import React from 'react';
import './App.css';

function App() {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
      <h1 className="text-5xl font-bold text-white mb-4">
        We are temporarily down for updagrades & maintenance
      </h1>
      <p className="text-lg text-white mb-8">
        Come back soon!  We are so excited to show you all of our awesome new updates!
      </p>
      
    </div>
  );
}

export default App;